// import Resizer from "react-image-file-resizer";
import imgPlaceholder from "../assets/images/icon-placeholder.svg";
import axios from "axios";
import {getConfigData} from "../middleware";

const countPristine = (photos) => {
  let photosLen = 0;
  photos.forEach((current) => {
    if (false === current.pristine) {
      photosLen++;
    }
  });
  return photosLen;
}

// const resizeFile = (file) => {
//   return new Promise((resolve) => {
//     Resizer.imageFileResizer(
//       file,
//       1600,
//       1600,
//       "JPEG",
//       40,
//       0,
//       (uri) => {
//         resolve(uri);
//       },
//       "base64"
//     );
//   });
// };

const loadImage = (files, photos, setPhotos, photosKey, cdnUri) => {
  // move reziseFile to lambda
  // for each photo:
  // 1. get presigned S3 url
  // 2. upload file to S3
  // 3. store returned url (CDN) into photos array
  console.log("loadImages - pre:",files);
  Promise.all(files.map(file => {
    return (new Promise((resolve, reject) => {
      try {
        const uri = uploadFile(file, photosKey);
        let val = {src: uri, size: file.size};
        resolve(val);
      } catch (e) {
        reject(e);
      }
    }));
  })).then((val) => {
    let _photos = [];

    let i = 0;
    for (const photo of photos) {
      if ((true === photo.pristine) && (val.length > i)) {
        _photos.push({src: cdnUri + val[i].src, pristine: false, size: val[i].size});
        i++;
      } else {
        _photos.push(photo);
      }
    }
    setTimeout(() => {
      /* Once all promises are resolved, update state with image URI array */
      setPhotos(_photos);
    }, 10000);
  }, error => {
    console.error("loadImages_error::" + error);
  });

}

const uploadFile = (file, photosKey) => {
  const config = getConfigData()[getConfigData().env].tempUploads;
  const url = config.schema + "://" + config.host + "/" + config.context + "/" + config.path;
  let uri = photosKey+"/"+file.name;
  // Getting the signed url
  axios(
    url + "?fileName=" + file.name + "&key=" + photosKey
  ).then(response => {
    if (response.data.success) {

      // Getting the url from response
      const url = response.data.fileUploadURL;
      const body = new FormData();
      body.append("file", file);

      axios({
        method: "PUT",
        url: url,
        data: file,
        headers: {"Content-Type": file.type},
      })
        .then(res => {
          console.log(res);
        })
        .catch(err => {
          console.log("error:Error Occured while uploading the file");
          return Promise.reject(err);
        });
    } else {
      console.error(response.data.error);
      return Promise.reject(response.data.error);
    }
  });
  return "uploads/" + uri;
}

const initPhotos = (photos = []) => {
  const photoArr = photos;
  if (photos.length <= 4) {
    let pad = 4 - photos.length;
    for (let i = 0; i < pad; i++) {
      photoArr.push({src: imgPlaceholder, pristine: true, size: 0});
    }
  }
  let photosLen = countPristine(photoArr);
  return {
    photos: photoArr,
    isCameraDisabled: (photosLen < 1)
  };
}

const getPhotosSize = (photos) => {
  let result = 0;
  for (const photo of photos) {
    if (false === photo.pristine) {
      result += photo.size;
    }
  }
  if (isNaN(result)) {
    result = 0;
  }
  return result;
}

export {initPhotos, countPristine, loadImage, getPhotosSize};