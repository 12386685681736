import LocationsRow from "../../LocationsRow";
import UpdateHours from "../../UpdateHours";
import {useTranslation} from "react-i18next";
import {useContext, useEffect, useRef} from "react";
import {MainFeedbackContext} from "../LocationFeedback";
import "./VerifyAddressHours.scss";
import {getStreetViewUrl} from "../../../util/Util";

const VerifyAddressHours = ({address, stepsNames, locationName}) => {
  const {review, setReview} = useContext(MainFeedbackContext);
  const {t} = useTranslation();
  const tr = t('hours', {returnObjects: true});

  const fieldRef = useRef(null);

  const currentAddress = review.address.addrLine1 + ", " + review.address.city + ", " + review.address.state + ", " + review.address.postalCode;

  useEffect(() => {
    fieldRef.current.scrollIntoView({
      behavior: "smooth",
    });
  }, []);

  const handleUpdateHours = (value) => {
    let oper = review;
    oper.operatingHours = value;
    setReview(oper);
  }
  return (
    <div className="review-verify" ref={fieldRef}>
      <p className="instructions">
        {tr.subtitle}
      </p>
      <div className='review-location'>
        <LocationsRow 
          address={address} 
          isEditable 
          stepsNames={stepsNames} 
          operatingHours={review.operatingHours} 
          locationName={locationName}
          thumbnail={getStreetViewUrl(currentAddress)}
          showStreetView="true"/>
      </div>
      <UpdateHours data={review.operatingHours} cta={tr.buttons.next} skipTitle handleUpdate={handleUpdateHours}
                   isFeedback={true} />
    </div>
  );
}

export default VerifyAddressHours;

